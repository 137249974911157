

























import { Component, Vue } from 'vue-property-decorator'
import { ProductService } from '../services/ProductService'
import { Product } from '@/models/Product.model'
import router from '@/router'

@Component
export default class ProductListView extends Vue {
  initialLoadingDone = false
  loading = false

  private products: Product[] = []
  private headers = [
    {
      text: 'Name',
      value: 'name',
      sortable: false
    },
    { value: 'actions', sortable: false }
  ]

  openProduct(product: Product) {
    router.push({ path: product.iri() })
  }

  async created() {
    this.loading = true
    this.products = await ProductService.getProducts()
    if (this.products.length === 1) {
      this.openProduct(this.products[0])
    }
    this.loading = false
    this.initialLoadingDone = true
  }
}
